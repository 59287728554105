module.exports = [
  {
    locale: 'pl',
    label: 'pl',
    default: true,
  },
  {
    locale: 'en',
    label: 'en',
  }
]